import React, { Suspense } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { isSSR, actualCountry } from "../utils/constants"
import Layout from "../components/layout"
import SEO from "../components/Seo"
import Spinner from "../components/Spinner"
import Resource from "../config/language.json"
import CardResource from "../components/ProductDetail/language.json"

const ProductDetail = React.lazy(() => import("../components/ProductDetail"))
const component = Resource[process.env.GATSBY_SITE_VERSION]
const adicionalComponent = CardResource[process.env.GATSBY_SITE_VERSION]

export default () => {
  const data = useStaticQuery(graphql`
    query {
      buildingMx: file(
        relativePath: {
          eq: "mx/landings/construccion-credito-para-construccion-iban-online-mexico.jpg"
        }
      ) {
        ...customFragmentImageFluid
      }
      buildingDo: file(
        relativePath: {
          eq: "do/landings/prestamo-para-construccion-iban-online.jpg"
        }
      ) {
        ...customFragmentImageFluid
      }
    }
  `)

  return (
    <>
      {!isSSR && (
        <Suspense fallback={<Spinner />}>
          <SEO
            title={component.buildingCredit.SEO.title}
            keywords={component.buildingCredit.SEO.keywords}
            description={component.buildingCredit.SEO.description}
          />
          <Layout>
            <ProductDetail
              pageData={{
                alt: component.buildingCredit.pageData.alt,
                credit: component.buildingCredit.pageData.credit,
                minAmount: component.buildingCredit.minAmount,
                minLoanDurationInMonths:
                  component.buildingCredit.minLoanDurationInMonths,
                titleProduct: component.buildingCredit.pageData.titleProduct,
                textProduct: component.buildingCredit.pageData.textProduct,
                href: component.buildingCredit.pageData.href,
                textBenefits: component.buildingCredit.pageData.textBenefits,
                image:
                  actualCountry === "do"
                    ? data.buildingDo.childImageSharp.fluid
                    : data.buildingMx.childImageSharp.fluid,
                cardDataUp: adicionalComponent.cardConstructionCredit,
                leftSymmetrical:
                  component.buildingCredit.pageData.leftSymmetrical,
                applicationType:
                  component.buildingCredit.pageData.applicationType,
                form: component.buildingCredit.form.inputs,
                cardDataHeader:
                  component.buildingCredit.pageData.cardDataHeader,
              }}
            />
          </Layout>
        </Suspense>
      )}
    </>
  )
}
